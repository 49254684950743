import React, { useState, useEffect } from "react";
import axios from "axios";

const Admin = () => {
  const [myData, setMyData] = useState([]);

  useEffect(() => {
    axios
      .get("http://54.197.35.166:4000/sms/view")
      .then((res) => setMyData(res.data));
  }, []);

  return (
    <div className="text-center bg-white mt-18">
      <div className="mt-8">
        <h3 className="text-xl font-semibold">User Data:</h3>

        {myData.map((item) => {
          const { id, name, email, phone, message } = item;
          return (
            <div className="" key={id}>
              <h1>{id}</h1>
              <h1>{name}</h1>
              <h1>{email}</h1>
              <h1>{phone}</h1>
              <h1>{message}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Admin;
