import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const CurrentYear = new Date().getFullYear();

  return (
    <div className="footer-wrapper bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div>
          <h6 className="font-medium text-gray-400 mb-4">About Us</h6>
          <p className="text-sm">
            Sierra Marine Services is a premier marine instrument firm based in Mumbai, renowned for our expertise and extensive clientele across key ports and regions in India. We take pride in delivering exceptional quality services and maintaining excellence at every step of our operations.
          </p>
          <div className="flex mt-4">
            <a href="https://www.facebook.com/sierramarineservices/" className="mr-4">
              <FaFacebookSquare size={24} />
            </a>
            <a href="https://www.instagram.com/p/B--VsyPlBc1/?igshid=ut5uta701doo" className="mr-4">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.linkedin.com/in/sierramarineservices/">
              <FaLinkedin size={24} />
            </a>
          </div>
        </div>
        <div>
          <h6 className="font-medium text-gray-400 mb-4">Quick Links</h6>
          <ul className="text-sm grid grid-cols-2 gap-2">
            <li className="py-1">
              <Link to="/">- Home</Link>
            </li>
            <li className="py-1">
              <Link to="/service">-   Service
</Link>
            </li>
            <li className="py-1">
              <Link to="/about">- About Us</Link>
            </li>
            <li className="py-1">
              <Link to="/contact">- Contact</Link>
            </li>
            <li className="py-1">
              <Link to="/Certificates">- Certificates</Link>
            </li>
             <li className="py-1">
              <Link to="/brochure">- Brochure</Link>
            </li>
            {/*<li className="py-1">
              <Link to="/cargogear">- Cargo Gear</Link>
            </li>
            <li className="py-1">
              <Link to="/supplyandrepairs">- Supply & Repairs</Link>
            </li>
            <li className="py-1">
              <Link to="/clients">- Clients</Link>
            </li>
            <li className="py-1">
              <Link to="/contactus">- Contact Us</Link>
            </li> */}
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400 mb-4">Keep in Touch</h6>
          <p className="text-sm">
            <FaMapMarkerAlt className="inline-block mr-2" />
            Empire Industrial Centrum, Rashtrakuta, Unit No 287, 3rd Floor Chikhloli, Kalyan-Badlapur Road, Ambernath West, Thane 421505
          </p>
          <p className="text-sm">
            <FaPhone className="inline-block mr-2" />
            <a href="tel:+918788745767">+91 87887 45767</a> | <a href="tel:+918452010594">+91 84520 10594</a>
          </p>
          <p className="text-sm">
            <FaEnvelope className="inline-block mr-2" />
            <a href="mailto:sierramarineservices@outlook.com">sierramarineservices@outlook.com</a>
          </p>
          <p className="text-sm">
            <FaEnvelope className="inline-block mr-2" />
            <a href="mailto:info@sierramarineservices.com">info@sierramarineservices.com</a>
          </p>
        </div>
      </div>
      <div className="text-center mt-8">
        <p className="text-sm">Copyright © {CurrentYear} - SierraMarineServices | All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
