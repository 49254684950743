import React, { useState } from "react";
import { FaPhone, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import axios from 'axios';

const Contact = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const jsonData = {};
      formData.forEach((value, key) => {
        jsonData[key] = value;
      });

      const response = await axios.post("http://54.197.35.166:4000/sms/contact", jsonData, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      
      console.log(response.data);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 20000); // Hide the success message after 20 seconds
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 bg-white">
      <div className="p-5">
        <div className="lg:ml-32">
          <h3 className="text-xl font-semibold text-blue-900 ">Address</h3>
          <p className="text-blue-900 mb-1">Empire Industrial Centrum, Rashtrakuta, Unit No 287, 3rd Floor</p>
          <p className="text-blue-900   mb-1">Chikhloli, Kalyan-Badlapur Road, Ambernath West,</p>
          <p className="text-blue-900  mb-1">Thane 421505</p>
        </div>
        <div className="mt-10 ml-2 sm:ml-32 md:mr-12 lg:mr-24 text-blue-900">

          <p className="text-blue-900 mb-2 flex items-center justify-center lg:justify-start">
            <FaPhone className="mr-2"/><a href="tel:+918878745767"> +91 87887 45767</a><a href="https://wa.me/918878745767"><FaWhatsapp className="ml-2" /></a>
          </p>
          <p className="text-blue-900 mb-2 flex items-center justify-center lg:justify-start">
            <FaPhone className="mr-2" />  <a href="tel:+918452010594"> +91 84520 10594</a><a href="https://wa.me/918452010594"><FaWhatsapp className="ml-2" /></a>
          </p>
          <p className="text-blue-900 flex items-center justify-center lg:justify-start">
            <FaEnvelope className="mr-2" /><a href="mailto:info@sierramarineservices.com"> info@sierramarineservices.com</a>
          </p>
          <p className="text-blue-900 flex items-center justify-center lg:justify-start ">
            <FaEnvelope className="mr-2" /><a href="mailto:sierramarineservices@outlook.com"> sierramarineservices@outlook.com</a>
          </p>  
        </div>
        <div className="mt-4 text-center lg:text-left">
       
        </div>
      </div>
      <div className="p-5">
        {!showSuccess && (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <input type="text" name="name" placeholder="Name" className="border border-gray-300 rounded-lg p-3" />
            <input type="email" name="email" placeholder="Email" className="border border-gray-300 rounded-lg p-3" />
            <input type="tel" name="phone" placeholder="Phone" className="border border-gray-300 rounded-lg p-3" />
            <textarea name="message" placeholder="Message" rows="4" className="border border-gray-300 rounded-lg p-3"></textarea>
            <button type="submit" className="bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300">Submit</button>
          </form>
        )}
        {showSuccess && (
          <div className="text-center lg:text-left text-green-600 mt-4">Message sent successfully!</div>
        )}
      </div>
      <div className="col-span-2 p-5">
        <div className="mb-4">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3395.582471195012!2d73.21447968977694!3d19.186408149801526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sEmpire%20Industrial%20Centrum%2C%20Rashtrakuta%2C%20Unit%20No%20287%2C%203rd%20Floor%20Chikhloli%2C%20Kalyan-Badlapur%20Road%2C%20Ambernath%20West%2C%20Thane%20421505!5e1!3m2!1sen!2sin!4v1708455937468!5m2!1sen!2sin"
            width="100%"
            height="300"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            className="pb-5"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default Contact;
