import React from 'react';


const About = () => {
  return (
    <section className="relative bg-white">
      <div 
      // className="bg-cover bg-center " 
      // style={{ backgroundImage: `url(${HeroImage})` 
      // }}
      >
        <div className="py-24 ">
          <h3 className="bg-opacity-10 font-semibold text-xl md:text-4xl text-center text-blue-900 mb-10">
            About Us
          </h3>
          <div className=" m-4 text-blue-900  px-28 ">
            <div className="text-lg  pb-3 ml-5 mr-10 text-blue-900">
              Sierra Marine Services is a premier marine instrument firm based in Mumbai, renowned for our expertise and extensive clientele across key ports and regions in India. <br></br>We take pride in delivering exceptional quality services and maintaining excellence at every step of our operations.
            </div>
            <p className="text-lg  pb-3 ml-5 mr-10 text-blue-900">
              At Sierra Marine Services, we are committed to fostering strong partnerships. Recognizing your company's reputation for delivering outstanding services, we aim to complement your efforts by providing top-notch Calibration, Automation, and LSA FFA services tailored to our regional expertise.
            </p>
            <p className="text-lg  pb-3 ml-5 mr-10 text-blue-900">
              Our state-of-the-art service stations in Mumbai and Gujarat ensure seamless Calibration and LSA/FFA services. With strategically positioned engineers, we prioritize efficiency and customer satisfaction by promptly serving customers at major and minor ports across India.
            </p>
            <p className="text-lg  pb-3 ml-5 mr-10 text-blue-900">
              Inaugurating our own Manufacturing Unit for Gas Detectors and Panels in the Mumbai region underscores our commitment to innovation and quality. As direct dealers for reputable brands like Safe Gas and Honeywell, and with a comprehensive inventory of leading makes, we ensure prompt availability of essential equipment and accessories.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white py-4 md:py-4 text-blue-900">
        <div className="ml-28 mx-auto px-8">
          <h4 className="text-xl md:text-2xl font-semibold mb-8 text-blue-900">Why Choose Sierra Marine Services</h4>
          <ul className=" text-white font-normal">
            <li className="  mb-6 text-blue-900">
              1. Our recently launched Manufacturing Unit ensures the highest quality Gas Detectors and Panels.
            </li>
            <li className="  mb-6 text-blue-900">
              2. As direct dealers for Safe Gas and Honeywell, we provide authentic and reliable gas detectors, sensors, and accessories.
            </li>
            <li className="  mb-6 text-blue-900">
              3. Our comprehensive inventory includes leading brands like Oldham, Draeger, Senko, RKI, and related accessories.
            </li>
            <li className="mb-6 text-blue-900">
              4. We maintain a ready stock of MMC, Hermetic – MK3, and GTEX Reconditioned spares and units for UTI.
            </li>
            <li className=" mb-6 text-blue-900">
              5. Our expertise extends to onboard Calibration and repair services for UTI, Fixed Gas detection systems, and Gas detectors.
            </li>
            <li className="  text-blue-900 mb-10">
              6. With over a decade of experience, we continue to deliver quality services and solutions to our valued customers.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
