import React from "react";


const QualityPolicy = () => {
  return (
    <section
      className="relative bg-white"
      style={{
        // background: `URL(${HeroImage})`,
        // backgroundSize: "cover",
        
      }}
    >
      <div className="py-24 px-28 sd:px-28 md:px-28 ">
        <h3 className="text-blue-900 font-semibold text-xl md:text-2xl pb-5 pt-2 text-center ">
          Quality Services
        </h3>
        <div className="text-blue-900 ">
          <p className=" text-xl sm:px-8 pt-8 bg-white bg-opacity-90 pb-5">
            Sierra Marine Services is committed to delivering exceptional
            quality services and maintaining excellence in all aspects of our
            operations. We are dedicated to continuous improvement at every
            stage of our processes.
          </p>
           


        </div>
       <div className="">
       <div className=" text-blue-900 text-2xl ml-8 sm:px-18 pt-8 pb-5 font-semibold bg-white bg-opacity-90">
            We achieve excellence by:
          </div>
          <div className=" ml-24 md:ml-8 font-normal">
  <div className="text-blue-900 bg-white">
    1. Cultivating strong relationships with our clients to enhance business performance and foster mutually beneficial is outcomes.
  </div>
  <div className="text-blue-900 font-normal  bg-white bg-opacity-90">
    2. Establishing a robust network ensure the seamless delivery of our services, consistently meeting and exceeding expectations.
  </div>
  <div className="text-blue-900  bg-white bg-opacity-90">
    3. Inspiring trust through accountability, ethical conduct ,& fostering open and honest communication within our organization.
  </div>
  <div className="text-blue-900 bg-white bg-opacity-90">
    4. Embracing diversity among our employees & fostering an inclusive environment that encourages collaboration & innovation.
  </div>
</div>


       </div>
      </div>
    </section>
  );
};

export default QualityPolicy;
