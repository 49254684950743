import React from 'react'
import Ssm from '../Assets/pdf/SMSB.pdf';

const brochure = () => {
  return (
    <div className='bg-cyan-900 h-screen'>
    <embed src={Ssm} type="application/pdf" width="100%" height="100%" />

</div>
  );
};


export default brochure